import "./Work.css";

const Work=()=>{
    return(
        <div className="work">
            <center><h1> Update Soon</h1></center>
        </div>
    )

}

export default Work;

